.head {
  padding-inline-start: 100px;
  color: black;
  font-family: 'VT323', monospace;
  display: flex;
  /* justify-content: center; */
}
.title {
  color: black;
  font-family: 'VT323', monospace;
  font-size: small;
}
.App {
  background-color: blanchedalmond;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  justify-content: center;
}
.Space {
  padding-inline-start: 30px;
  padding-inline-end: 30px;
}
.Container {
  /* position: absolute; */
  margin: 20px;
  color:antiquewhite;
  text-align: center;
  font-family: 'VT323', monospace;
  padding: 0px;
  border: 10mm ridge rgba(61, 61, 52, 0.6);
  background-color: #282c34;
  border-radius: 25px;
  /* transform: translate(50% ,50%); */
}

.blink{
  animation: blink 1s steps(1, end) infinite;
}
@keyframes blink{
  0%{opacity: 1;}
  50%{opacity: 0;}
  100%{opacity: 1;}
}

.blink2{
  animation: blink 1.3s steps(1, end) infinite;
}
@keyframes blink{
  0%{opacity: 1;}
  50%{opacity: 0;}
  100%{opacity: 1;}
}
.buttonRadius {
  background-color: black;
  border-style: outset;
  color: white;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  border-radius: 40%;
  margin: 4px 70px;
}